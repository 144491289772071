<template>
  <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sign__content">
                        <!-- authorization form -->
                        <form v-on:submit.prevent="VerifyMDN()" class="sign__form">
                            <a href="index.php" class="sign__logo">
                                <img src="assets/img/logoSvg.svg" alt="">
                            </a>
                            <div class="main" dir="ltr">
                                <p class="label">ادخل كود التفعيل</p>
                                <!-- SMS Code input -->
                                <div class="fieldset">
                                    <label class="box"><input class="field" v-model="otp1" type="text" placeholder="•" /></label>
                                    <label class="box"><input class="field" v-model="otp2" type="text" placeholder="•" /></label>
                                    <label class="box"><input class="field" v-model="otp3" type="text" placeholder="•" /></label>
                                    <label class="box"><input class="field" v-model="otp4" type="text" placeholder="•" /></label>
                                </div>
                                <!-- End SMS Code input -->
                            </div>
                            <div class="sign__group">
                                <button class="sign__btn" type="submit">تاكيد</button>
                            </div>
                            <div class="sign__group sign__group--checkbox">
                                <center>
                                    <input id="remember" name="remember" type="checkbox" checked="checked">
                                    <label for="remember">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; تجديد  الإشتراك تلقائيا</label>
                                </center>
                            </div>
                        </form>
                        <!-- end authorization form -->
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: 'Virefys',
  data() {
    return {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    };
  },
  created() {
    this.requestId = this.$cookie.getCookie("requestId");
    this.mdn = this.$cookie.getCookie("mdn");
    this.product_code = this.$cookie.getCookie("product_code");
    this.Content_id = this.$cookie.getCookie("Content_id");
    this.cat_id = this.$cookie.getCookie("cat_id");
    this.SUBID = this.$cookie.getCookie("SUBID");
  },
  methods: {
     VerifyMDN() {
         const toast = useToast();
         const otp = this.otp1 + this.otp2 +this.otp3 +this.otp4 ;
         axios
        .get(
          "DSPVerify.php?OTP="+otp+"&requestID="+this.requestId+"&msisdn="+this.mdn+"&product_code="+this.product_code+"&SUBID="+this.SUBID+""
        )
        .then((response) => {
          if (response.data.error == false && response.data.responseCode == 0 ) {
            this.data = { mdn: "", otp: "" };
            this.$cookie.removeCookie("product_code");
            this.$cookie.removeCookie("requestId");
            this.$cookie.removeCookie("mdn");
            this.$cookie.removeCookie("Content_id");
            this.$cookie.removeCookie("Catogery_id");
            this.$cookie.removeCookie("SUBID");

            this.$cookie.setCookie('mdn', this.mdn, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('Content_id', this.Content_id, {
                 expire: response.data.remming_minutes,
             })
             this.$cookie.setCookie('minutes', response.data.remming_minutes, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('cat_id', this.cat_id, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('product_code', this.product_code, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('SUBID', this.SUBID, {
                 expire: 60 * response.data.remming_minutes,
             })
            toast.success("تم تاكيد رمز التفعيل يمكنك الاستمتاع بتجربة فريدة من منصة نشوف", {
                timeout: 4000
             });
            setTimeout(() => this.$router.push({ path: "Content" }), 2000);

          } else if (response.data.error == false && response.data.status == 0 && (response.data.responseCode == 118 || response.data.responseCode == 1000 || response.data.responseCode == 117 )) {
            this.data = { mdn: "", otp: "" };
            this.$cookie.removeCookie("product_code");
            this.$cookie.removeCookie("requestId");
            this.$cookie.removeCookie("mdn");
            this.$cookie.removeCookie("Content_id");
            this.$cookie.removeCookie("cat_id");
            this.$cookie.setCookie('mdn', this.mdn, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('Content_id', this.Content_id, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('minutes', response.data.remming_minutes, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('cat_id', this.cat_id, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('product_code', this.product_code, {
                 expire: 60 * response.data.remming_minutes,
             })
             this.$cookie.setCookie('SUBID', this.SUBID, {
                 expire: 60 * response.data.remming_minutes,
             })
             toast.success("تم تاكيد رمز التفعيل يمكنك الاستمتاع بتجربة فريدة مع منصة نشوف  ", {
                timeout: 4000
             });
            setTimeout(() => this.$router.push({ path: "Content" }), 4000);
          } else {
             toast.error("رمز التفعيل ليس صحيح", {
                timeout: 2000
             });
          }
        });
     }
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'assets/js/Verify.js')
    document.head.appendChild(externalScript)
  },
}
</script>

<style>

</style>