<template>
  <Header />
  <Suspense>
    <template #default>
      <Virefys />
    </template>
    <template #fallback>
      <Loding />
    </template>
  </Suspense>
  <Footer />
</template>

<script>
import { Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import Header from "@/components/Include/Header.vue";
import Footer from "@/components/Include/Footer.vue";
import Virefys from "@/components/Users/Virefys.vue";

export default {
  name: "Virefy",
  components: {
    Suspense,
    Loding,
    Header,
    Footer,
    Virefys,
    
  },
};
</script>